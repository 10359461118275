<template>
  <div>
    <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
      <h6 class="h2 text-white d-inline-block mb-0"><i class="ni ni-chart-bar-32"></i> Data Sparing</h6></h1>
    
    <base-header class="pb-6">
      <b-row>
        <b-col xl="12" md="12">
          <b-card >
              <h3>Cari Berdasarkan</h3>
              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">

                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>


                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
              </div>
              <base-button size="md" type="primary" @click="getBaseOnStatus">Tampilkan</base-button>

          </b-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <div class="col-lg-12" v-if="isLoading">
          <h1 class="text-center">Loading...</h1>
        </div>
        <div v-else v-for="(item, index) in listData" :key="index" class="col-xl-6 col-lg-12">
          <div class="card" style="box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);">
            <div class="container my-3">
              <div class="header-desc">
                <div>{{ item.sensor_type }}</div>
                <div class="bmal">
                  BMAL: {{ item.bmal_lower_threshold }}  -  {{ item.bmal_upper_threshold }}
                </div>
              </div>
              <div class="header-desc">
                <div class="bmal">Menampilkan grafik dari {{ formatDate(item.start_timestamp) }} - {{ formatDate(item.end_timestamp) }}</div>
                <div class="bmal">Hasil Pengukuran: {{ item.measurement_avg.toFixed(2) }}</div>
              </div>
            </div>
            <hr class="m-0" />
            <div class="container">
              <div class="row">
                <div class="col py-3">
                  <div>
                    <VueApexCharts  type="area" :options="options" :series="deviceSeriesData(item.line)"></VueApexCharts>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12" v-if=" listData.length == 0">
          <div class="card" style="box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);">
            <div class="container my-3">
              <h1 class="text-center">Belum ada data</h1>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import API from '../../api/base_url'
import { Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
// import apexchart from 'apexcharts'; // For Vue 2

export default {
  components: {
    VueApexCharts,
    BaseHeader,
    DataSparingChart,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
    this.log_as =  localStorage.getItem('as')
    this.getCompliencePoint()
    this.getCompanyName()
    this.getProvince()
  },
  computed: {
    deviceHistory() {
      return this.responseData.data ? this.responseData.data.device_history : [];
    },
  },
  data() {
    return {
      listDataCompliencePoints: [],
      log_as:"",
      responseData: {}, // Menyimpan respons data dari Axios
      options: {
        annotations: {
          yaxis: [
            {
              y: 7.26, // Nilai minimum (min)
              borderColor: 'red',
              label: {
                borderColor: 'red',
                style: {
                  color: '#fff',
                  background: 'red'
                },
                text: 'Dibawah Baku mutu'
              },
            },
            {
              y: 7.3, // Nilai maksimum (max)
              borderColor: 'red',
              label: {
                borderColor: 'red',
                style: {
                  color: '#fff',
                  background: 'red',
                },
                text: 'Diatas Baku mutu'
              },

            }
          ]
        },
        colors:['#29664a'],
        chart: {
          id: 'vuechart-example',
          toolbar:{
            show:false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        },

        grid: {
          show: true,
          borderColor: '#ddd', // Warna garis kotak-kotak
          strokeDashArray: 4,   // Gaya garis kotak-kotak
          position: 'back',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },

        
        
        tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      },
      series: [{
        name: 'series-1',
        data: []
      }],
      data: [],
      listData: [],
      provideSelect: {
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false
    };
  },
  computed: {
    chartData() {
      if (this.loading) return [];
      let data = {
        labels: [],
        datasets: [
          {
            borderColor: "#29664a",
            data: [],
            range: { min: 0, max: 0 },
            label: "pH",
            id: 1,
          },
          {
            borderColor: "#29664a",
            data: [],
            range: { min: 0, max: 0 },
            label: "COD (mg/l)",
            id: 2,
          },
          {
            borderColor: "#29664a",
            data: [],
            range: { min: 0, max: 0 },
            label: "TSS (mg/l)",
            id: 3,
          },
          {
            borderColor: "#29664a",
            data: [],
            range: { min: 0, max: 0 },
            label: "NH3N (mg/l)",
            id: 4,
          },
          {
            borderColor: "#29664a",
            data: [],
            range: { min: 0, max: 0 },
            label: this.threshold.debit[2] ? `Debit (${this.threshold.debit[2]})` : `Debit`,
            id: 5,
          },
        ],
      };
      data.labels = this.data.map(({ timestamp }) => moment.unix(timestamp).format("HH:mm"));
      data.datasets[0].data = this.getDatasets("pH");
      data.datasets[1].data = this.getDatasets("COD");
      data.datasets[2].data = this.getDatasets("TSS");
      data.datasets[3].data = this.getDatasets("NH3N");
      data.datasets[4].data = this.getDatasets("Debit");

      data.datasets[0].range.min = this.threshold.pH[0];
      data.datasets[0].range.max = this.threshold.pH[1];
      data.datasets[1].range.min = this.threshold.cod[0];
      data.datasets[1].range.max = this.threshold.cod[1];
      data.datasets[2].range.min = this.threshold.tss[0];
      data.datasets[2].range.max = this.threshold.tss[1];
      data.datasets[3].range.min = this.threshold.nh3n[0];
      data.datasets[3].range.max = this.threshold.nh3n[1];
      data.datasets[4].range.min = this.threshold.debit[0];
      data.datasets[4].range.max = this.threshold.debit[1];

      return data;
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      const queryParams = this.queryParams();

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) =>
        value !== undefined && value !== null && value !== ""
        )
      );


      API.get(`sparing-chart`, { params, headers })
        .then(({ data }) => {
          if (this.isEmptyObject(data.data)) {
            this.listData = [];
          } else {
            this.listData = data.data;
            this.pagination.total = data.meta.pagination.total_items;
          }
        
          this.isLoading = false;
        })
        .catch((err) => {
          Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
          this.isLoading = false
        });
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`stations`, { headers })
        .then(({ data: content }) => {
          this.listDataCompliencePoints = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }

    const queryParams = this.queryParams();


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

  
   

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`sparing-chart` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    queryParams(){

      const queryParams = {
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },

    // New
    getSatuan(sensor_type) {
      switch (sensor_type) {
        case 'Debit':
          return ""
          break;
        case 'pH':
          return ""
          break;
      
        default:
          break;
      }
    },
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD/MM/YYYY');
      return humanDate;
    },
    formatDateLabel(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('HH:mm');
      return humanDate;
    },
    // Fungsi untuk menggabungkan data dan timestamps
    gabungArray(data, timestamps) {
        // Membuat array kosong untuk menampung pasangan x dan y
        var hasil = [];

        // Memastikan jumlah elemen dalam kedua array sama
        if (data.length !== timestamps.length) {
            console.error("Jumlah elemen dalam kedua array harus sama");
            return null;
        }

        // Menggabungkan data dan timestamps
        for (var i = 0; i < data.length; i++) {
            var pasangan = {
                x: this.formatDateLabel(timestamps[i]),
                y: data[i].toFixed(2)
            };
            hasil.push(pasangan);
        }

        return hasil;
    },

    deviceSeriesData(device) {

      return [{
        name: 'tes',
        data: this.gabungArray(device.data, device.timestamps)
      }];
    },
  },
};
</script>


<style lang="scss">
.header-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #29664a;
  font-weight: 600;
}
.bmal {
  font-size: 12px;
}
</style>
